<script setup lang="ts">
</script>

<template>
  <div class="unauthenticated">
    <slot></slot>
  </div>
</template>

<style scoped>
.unauthenticated {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
</style>