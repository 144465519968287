<script lang="ts" setup>
import NominatimSearch from './NominatimSearch.vue'

const props = defineProps<{
  query?: string,
  osmType?: string,
  osmId?: string,
  osmName?: string,
}>();

const emit = defineEmits(['update']);

const onClickDelete = () => {
  emit('update',
    {
      osmName: null,
      osmType: null,
      osmId: null,
    }
  );
};

const onSearchUpdate = (event: {
  latitude: string,
  longitude: string,
  osmName: string,
  osmType: string,
  osmId: string,
}) => {
  emit('update', event)
};
</script>

<template>
  <template v-if="osmType && osmId && osmName">
    Selected place: <span class="selected">{{ osmName }}</span>

    <button class="delete-button" type="button" @click="onClickDelete">Remove</button>
  </template>
  <template v-else>
    <NominatimSearch :query="query" @update="onSearchUpdate" />
  </template>
</template>

<style scoped>
.selected {
  font-weight: bold;
}

.delete-button {
  margin-left: 1em;
  font-size: 0.75em;
}
</style>
