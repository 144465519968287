<script lang="ts" setup>
import { Link } from '@inertiajs/vue3'

const props = defineProps<{
  editPagePath?: string,
}>();

const linkText = 'expanding it';
</script>

<template>
  <div>This Page is a stub. You can help Bushwick.fun by
    <Link v-if="editPagePath" :href="editPagePath">{{ linkText }}</Link><template v-else>{{ linkText }}</template>.
  </div>
</template>

<style scoped>
div {
  padding: 1em;
  background: #ffffbd;
  border: 5px ridge #fa9bff;
  border-radius: 5px;
  text-align: center;
}
</style>
