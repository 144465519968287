<script lang="ts" setup>
import { ref } from 'vue'
import bushwickBounds from './bushwickBounds'

const props = defineProps<{
  query?: string,
}>();

const emit = defineEmits(['update']);

const bounded = ref(true);
const query = ref(props.query);
const loading = ref(false);
const submitted = ref(false);
const results = ref();

const onSubmit = async () => {
  if (!query.value) {
    return
  }

  const queryString = new URLSearchParams({
    format: 'jsonv2',
    addressdetails: '1',
    dedupe: '1',
    countrycodes: 'us',
    bounded: bounded.value ? '1' : '0',
    viewbox: bushwickBounds.toString(),
    q: query.value,
  });

  const url = `https://nominatim.openstreetmap.org/search?${queryString}`

  loading.value = true;
  const response = await fetch(url);
  results.value = await response.json();
  loading.value = false;
  submitted.value = true;
};

const onClickResult = (result: {
  display_name: string,
  name?: string,
  osm_type: string,
  osm_id: number,
  lat: string,
  lon: string,
  address: {
    house_number?: string,
    road?: string,
    postcode?: string,
  },
}) => {
  let addressName;

  if (result?.address?.house_number && result?.address?.road) {
    addressName = `${result?.address?.house_number} ${result?.address?.road}`
  }

  emit('update', {
    latitude: result.lat,
    longitude: result.lon,
    osmName: result.name || addressName || result.display_name,
    osmType: result.osm_type,
    osmId: String(result.osm_id),
  });
};
</script>

<template>
  <form @submit.prevent="onSubmit">
    <div>
      <label>Search for a place or address:</label>
    </div>
    <input type="search" :disabled="loading" placeholder="Place name or address" v-model="query" />
    <button :disabled="loading" type="submit">Go</button>

    <label v-if="submitted" class="bounded-checkbox">
      <input :disabled="loading" type="checkbox" v-model="bounded" @change="onSubmit" />
      Limit search to Bushwick
    </label>
  </form>

  <p v-if="loading">Loading...</p>

  <ul v-else-if="results?.length">
    <li v-for="result in results" :key="result.place_id">
      <button type="button" @click="onClickResult(result)">
        <template v-if="result.name">
          {{ result.name }}<br>
        </template>
        <template v-else>
          Unnamed Place<br>
        </template>

        <template v-if="result.address.house_number || result.address.road">
          {{ result.address.house_number }} {{ result.address.road }}<br>
        </template>

        {{ result.address.postcode }}
      </button>
    </li>
  </ul>
  <template v-else-if="submitted && !loading">
    <p>Didn't find nuthin'</p>
  </template>
</template>

<style scoped>
.bounded-checkbox {
  display: block;
  cursor: pointer;
}

button[disabled] {
  opacity: 0.75;
}
</style>
