<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue'
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css';
import mapFactory from './mapFactory'

const props = defineProps<{
  latitude?: number,
  longitude?: number,
  editable?: boolean,
}>();

const emit = defineEmits(['update']);

const mapContainer = ref();
let map: maplibregl.Map;
const marker = new maplibregl.Marker()

onMounted(() => {
  if (!props.latitude || !props.longitude) {
    return
  }

  map = mapFactory({
    container: mapContainer.value,
    center: [props.longitude, props.latitude],
  });

  if (props.editable) {
    map.on('dragend', (event) => {
      const center = map.getCenter();
      emit('update', { latitude: center.lat, longitude: center.lng })
    })
  }

  marker
    .setLngLat([props.longitude, props.latitude])
    .addTo(map);
})

const setCoordinates = (latitude: number, longitude: number) => {
  map.setCenter([longitude, latitude]);
  marker.setLngLat([longitude, latitude]);
};

watch(() => [props.latitude, props.longitude], ([latitude, longitude]) => {
  if (latitude && longitude) {
    setCoordinates(latitude, longitude)
  }
})
</script>

<template>
  <div class="map" ref="mapContainer"></div>
</template>

<style scoped>
.map {
  width: 100%;
  height: 500px;
}
</style>
