<script setup lang="ts">
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'

const props = defineProps<{
  query?: string,
}>();

const emit = defineEmits<{
  (e: "escape"): void
}>()

const form = useForm({
  query: props.query,
});

const submit = () => {
  if (form.query) {
    form.get('/search', { preserveState: true });
  }
}

const onKeydown = (e: KeyboardEvent) => {
  if (e.key === "Escape") {
    emit("escape");
  }
};

const timeoutId = ref();
const onKeyup = (e: KeyboardEvent) => {
  window.clearTimeout(timeoutId.value);
  timeoutId.value = window.setTimeout(() => {
    submit()
  }, 100)
};

const input = ref<HTMLInputElement>();

const focus = () => {
  input.value?.focus()
};

defineExpose({ focus });
</script>

<template>
  <form class="form" @submit.prevent="submit">
    <input @keydown="onKeydown" @keyup="onKeyup" ref="input" type="search" placeholder="Search for (or create) a page"
      v-model="form.query" />
  </form>
</template>

<style scoped>
input {
  display: block;
  font-size: 1.25em;
  padding: 0.25em;
  width: 100%;
}

input::-webkit-search-cancel-button {
  appearance: none;
}
</style>
