<script lang="ts" setup>
import UnauthenticatedLayout from "~/layouts/UnauthenticatedLayout.vue"
import DiscordIcon from "~/components/DiscordIcon.vue"
const authenticityToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')

defineOptions({ layout: UnauthenticatedLayout })
</script>

<template>
  <form method="post" action="/auth/discord">
    <input type="hidden" name="authenticity_token" :value="authenticityToken" autocomplete="off">
    <button type="submit">
      <DiscordIcon />
      What's your Discord?
    </button>
  </form>
</template>

<style scoped>
button {
  padding: 1em;
  border-radius: 4px;
  background: #5865F2;
  color: #fff;
}

button svg {
  display: inline-block;
  position: relative;
  top: 3px;
  width: 20px;
  margin-right: 5px;
  fill: #fff;
}
</style>
