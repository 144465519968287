<script setup lang="ts">
import { ref } from 'vue'
import { router, Link } from '@inertiajs/vue3'
import '@fontsource/slackey';
import Search from '~/components/Search.vue'
import Footer from "~/components/Footer.vue"

const props = defineProps<{
  currentUser?: { displayName: string, avatarUrl: string },
  query?: string,
}>();

const currentRoute = ref();

const search = ref<typeof Search>();
const searchVisible = ref(false);

const showSearch = () => {
  searchVisible.value = true;

  window.setTimeout(() => {
    search.value?.focus();
  })
}

const hideSearch = () => {
  searchVisible.value = false;
}

router.on('navigate', (event) => {
  currentRoute.value = location.pathname.substring(1);
  if (event.detail.page.component !== 'Pages/Search') {
    hideSearch();
  } else {
    showSearch();
  }
})
</script>

<template>
  <div class="page">
    <header v-if="currentUser">
      <template v-if="!searchVisible">
        <h1 class="site-title">
          <Link href="/">Bushwick.fun!</Link>
        </h1>
        <div class="right-side">
          <button class="show-search" @click="showSearch">🔍</button>
          <Link class="avatar" href="/you">
          <img width="40" height="40" :src="currentUser.avatarUrl" :alt="`Avatar for ${currentUser.displayName}`" />
          </Link>
        </div>
      </template>
      <div v-show="searchVisible" class="search-wrapper">
        <Search :key="currentRoute" :query="query" class="search" @escape="hideSearch" ref="search" />
        <button class="hide-search" @click="hideSearch">❌</button>
      </div>
    </header>

    <slot></slot>
  </div>
  <Footer />
</template>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --pale-aqua-values: 138, 223, 255;
  --pale-aqua: rgb(var(--pale-aqua-values));

  --almost-white-values: 240, 240, 240;
  --almost-white: rgb(var(--almost-white-values));
}

html {
  min-width: 375px;
}

body {
  background: var(--pale-aqua);
  margin: 0;
  font-size: 19px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}

a {
  color: #0044ff;
  text-decoration-color: #cc00ff;
  text-decoration-thickness: 2.4px;
}

a:hover,
a:focus {
  text-decoration: none;
}

button {
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.3em;
  border-radius: 4px;
  background: #844cde;
  color: #fff;
  border: 4px outset rgb(167, 167, 233);
}

button:active {
  border-style: inset;
}

s {
  opacity: 0.75;
}

input {
  font-size: 1em;
  padding: 0.4em;
}

li {
  margin: 0.4em;
}

ul li::marker {
  content: '⁕ ';
  font-size: 0.9em;
}

.page {
  margin: 1em auto;
  max-width: 800px;
  padding: 1em;
  padding-top: 0;
  background: #e4ffcc;
  color: rgb(117, 40, 188);
  border-radius: 1em;
  border: 5px ridge #7c00ff;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.25em 0;
}

.site-title {
  font-size: 1.5em;
  margin: 0;
}

.site-title a {
  font-family: 'Slackey', system-ui;
  text-decoration: none;
  font-weight: normal;
  color: #ff00fb;
  font-size: min(calc(10px + 5vw), 2.3em);
  text-shadow: 0.05em 0.05em 2px black;
}

.right-side {
  display: flex;
}

header .search input,
.hide-search,
.show-search,
header .avatar {
  height: min(7vw, 2.3em);
}

.hide-search,
.show-search,
header .avatar {
  background: #eee;
  font-size: min(3vw, 1.2em);
  line-height: 1;
  width: min(7vw, 2.3em);
  height: min(7vw, 2.3em);
  border: 3px ridge #7c00ff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.hide-search,
.show-search {
  padding: 0;
  text-align: center;
  margin-right: 10px;
}

header .avatar {
  position: relative;
  padding: 0;
  overflow: hidden;
  background: none;
}

header .avatar img {
  width: 100%;
  height: 100%;
}

.search-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.search-wrapper .search {
  flex-grow: 1;
}
</style>
