import type { StyleSpecification } from 'maplibre-gl'

const mapStyle: StyleSpecification = {
  version: 8,
  name: 'Blank',
  center: [0, 0],
  zoom: 0,
  sources: {
    'raster-tiles': {
      attribution: '© <a rel="noopener noreferrer" target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
      type: 'raster',
      tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
      tileSize: 256,
      minzoom: 0,
      maxzoom: 19
    }
  },
  layers: [
    {
      id: 'background',
      type: 'background',
      paint: {
        'background-color': '#e0dfdf'
      }
    },
    {
      id: 'simple-tiles',
      type: 'raster',
      source: 'raster-tiles'
    }
  ],
};

export default mapStyle;
