<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3'
import LogoutButton from '~/components/LogoutButton.vue';

const props = defineProps<{
  currentUser: { settings: { editSlug: boolean, manualMapMarkerPositioning: boolean } },
}>();

const form = useForm({
  manual_map_marker_positioning: props.currentUser.settings.manualMapMarkerPositioning,
  edit_slug: props.currentUser.settings.editSlug,
});
</script>

<template>
  <h1>Settings</h1>

  <form class="form" @submit.prevent="form.put('/settings')">
    <label>
      Enable manual map marker positioning: <input type="checkbox" v-model="form.manual_map_marker_positioning" />
    </label>
    <label>
      Allow changing URLs of Pages: <input type="checkbox" v-model="form.edit_slug" />
    </label>

    <div>
      <button type="submit">Save</button>
    </div>
  </form>

  <p>Had enough? The door's that way 👇</p>
  <LogoutButton />
</template>

<style scoped>
label {
  cursor: pointer;
  display: block;
  margin: 1em 0;
}
</style>
