<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted } from "vue"
import { useForm } from '@inertiajs/vue3'
import type { InertiaForm } from '@inertiajs/vue3'
import MarkdownCheatSheet from './MarkdownCheatSheet.vue'
import PagePreview from './PagePreview.vue'
import OpenStreetMapSelector from './OpenStreetMapSelector.vue'
import Map from './Map.vue'

const props = defineProps<{
  editSlug?: boolean,
  manualMapMarkerPositioning?: boolean,
  actionUrl: string,
  editing?: boolean,
  title?: string,
  slug?: string,
  body?: string,
  images?: { id: string, url: string }[],
  enabledImageIds?: string[],
  externalLinks?: { url: string }[],
  latitude?: number,
  longitude?: number,
  osmType?: string,
  osmId?: string,
  osmName?: string,
  tagsAsStrings?: string[],
}>();

const form: InertiaForm<{
  title?: string,
  slug?: string,
  body?: string,
  images?: FileList,
  enabled_image_ids: string[],
  external_links: { url: string }[],
  latitude?: number,
  longitude?: number,
  osm_type?: string,
  osm_id?: string,
  tags_as_strings?: string[],
}> = useForm({
  title: props.title,
  slug: props.slug,
  body: props.body,
  images: undefined,
  enabled_image_ids: props.enabledImageIds || [],
  external_links: props.externalLinks || [],
  latitude: props.latitude,
  longitude: props.longitude,
  osm_type: props.osmType,
  osm_id: props.osmId,
  tags_as_strings: props.tagsAsStrings,
});

const osmName = ref(props.osmName);

const submit = () => {
  if (form.external_links.length) {
    form.external_links = form.external_links?.filter(({ url }) => url)
  } else {
    form.external_links = []
  }
  form.submit(formMethod, props.actionUrl)
};

const enabledImages = computed(() => {
  return props.images?.filter((image) => form.enabled_image_ids.includes(image.id)) || []
});

const onFileInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files) {
    form.images = target.files
  }
};

const unlinkImage = (deleteThisId: string) => {
  form.enabled_image_ids = form.enabled_image_ids.filter(id => id !== deleteThisId)
};

const addLink = () => {
  form.external_links.push({ url: '' })
};

const onOsmSelectorUpdate = (selector: {
  latitude: string,
  longitude: string,
  osmName: string,
  osmType: string,
  osmId: string,
}) => {
  if (selector?.latitude && selector?.longitude) {
    form.latitude = Number.parseFloat(selector.latitude);
    form.longitude = Number.parseFloat(selector.longitude);
  }
  form.osm_type = selector.osmType;
  form.osm_id = selector.osmId;
  osmName.value = selector.osmName;
};

const onMapUpdate = ({ latitude, longitude }: { latitude: number, longitude: number }) => {
  form.latitude = latitude;
  form.longitude = longitude;
};

const onTagsChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  form.tags_as_strings = target.value.split(',').map(tag => tag.trim()).filter((tag) => !!tag)
};

const beforeUnload = (event: Event) => {
  if (form.isDirty) {
    event.preventDefault();
  }
};

onMounted(async () => {
  addEventListener('beforeunload', beforeUnload);
})

onUnmounted(async () => {
  removeEventListener('beforeunload', beforeUnload);
})

const previewMode = ref(false);
const markdownCheatSheetVisible = ref(false);

const markdownCheatSheetButtonText = computed(() => {
  return !markdownCheatSheetVisible.value ? "💡 Markdown cheat sheet" : "🙅‍♀️ I don't need the cheat sheet right now";
});

const formMethod = props.editing ? 'put' : 'post';
const disabled = form.processing;
</script>

<template>
  <form class="form" @submit.prevent="submit">

    <div v-if="form.hasErrors" class="error">
      <p v-for="message in form.errors">⚠️ {{ message }}</p>
      <p>
        <button @click="form.reset(); form.clearErrors()" type="button">oh, sorry</button>
      </p>
    </div>

    <input class="text-input" name="title" type="text" v-model="form.title" :required="true" :autofocus="true"
      placeholder="Some cool page title" :disabled="disabled" />
    <input v-if="editSlug" class="text-input" type="text" v-model="form.slug" placeholder="The last bit of the URL"
      :disabled="disabled" />

    <PagePreview v-if="previewMode" :markdown="form.body" />
    <textarea v-else class="body" v-model="form.body"></textarea>

    <div class="editor-row">
      <button type="button" @click="markdownCheatSheetVisible = !markdownCheatSheetVisible">
        {{ markdownCheatSheetButtonText }}
      </button>
      <button type="button" @click="previewMode = !previewMode">{{ previewMode ? 'Back to editing' : 'Preview'
        }}</button>
    </div>
    <template v-if="markdownCheatSheetVisible">
      <MarkdownCheatSheet />
      <button type="button" @click="markdownCheatSheetVisible = false">{{ markdownCheatSheetButtonText }}</button>
    </template>

    <div class="images">
      <div v-for="image in enabledImages" class="image-card">
        <img :src="image.url" />
        <button type="button" @click="unlinkImage(image.id)">Delete</button>
      </div>
    </div>

    <h3>Add Images</h3>
    <input type="file" multiple @input="onFileInput" />

    <h3>External Links</h3>
    <ul>
      <li v-for="link in form.external_links">
        <input type="url" placeholder="URL" v-model="link.url" />
      </li>
    </ul>
    <button @click="addLink" type="button">Add link</button>

    <div class="osm-selector">
      <OpenStreetMapSelector :query="form.title" :osmType="form.osm_type" :osmId="form.osm_id" :osmName="osmName"
        @update="onOsmSelectorUpdate" />
    </div>

    <template v-if="form.latitude && form.longitude">
      <Map :editable="manualMapMarkerPositioning" :latitude="form.latitude" :longitude="form.longitude"
        @update="onMapUpdate" />
    </template>

    <div class="tags">
      <div>Tags:</div>
      <input type="text" :value="form.tags_as_strings?.join(', ')" @change="onTagsChange" />
    </div>

    <button class="submit-button" type="submit" :disabled="disabled">Save</button>
  </form>
</template>

<style scoped>
.text-input,
.body {
  font-size: 17px;
  padding: 0.5em;
}

.text-input {
  width: 100%;
  margin-bottom: 1em;
}

.body {
  width: 100%;
  min-height: 500px;
  height: auto;
}

.editor-row {
  display: flex;
  justify-content: space-between;
}

.images {
  display: flex;
  flex-wrap: wrap;
}

.image-card {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  max-width: 120px;
  margin-right: 1em;
}

.image-card img {
  max-width: 40px;
  max-height: 40px;
}

.osm-selector {
  margin: 1.5em 0;
}

.tags {
  margin: 1em 0;
}
</style>
