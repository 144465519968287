import maplibregl from 'maplibre-gl'
import type { LngLatLike } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css';
import mapStyle from './mapStyle'

export default ({ container, center, zoom, bounds }: {
  container: string | HTMLElement, center?: LngLatLike | undefined, zoom?: number, bounds?: [LngLatLike, LngLatLike]
}) => {
  const map = new maplibregl.Map({
    container,
    style: mapStyle,
    attributionControl: { customAttribution: undefined },
    center,
    zoom: zoom || 15,
    bounds,
    minZoom: 9,
    maxZoom: 19,
    cooperativeGestures: true,
    dragRotate: false,
    doubleClickZoom: true,
    boxZoom: false,
    touchZoomRotate: true,
    touchPitch: false,
    pitchWithRotate: false,
  });
  map.touchZoomRotate.disableRotation();

  return map;
};