<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import maplibregl from 'maplibre-gl'
import bushwickBounds from './bushwickBounds'
import mapFactory from './mapFactory'

const props = defineProps<{
  pages: { title: string, url: string, thumbnailUrl: string, latitude: number, longitude: number }[],
}>();

const mapContainer = ref();
let map: maplibregl.Map;

onMounted(() => {
  map = mapFactory({
    container: mapContainer.value,
    bounds: bushwickBounds,
  });

  props.pages.forEach(({ title, url, thumbnailUrl, latitude, longitude }) => {
    const marker = new maplibregl.Marker({ className: 'marker' });
    let html = `<a href="${url}">${title}</a>`;
    if (thumbnailUrl) {
      html += `<br><img width="50" height="50" src="${thumbnailUrl}" />`
    }
    const popup = new maplibregl.Popup({ offset: 25 }).setHTML(
      html
    );

    marker
      .setLngLat([longitude, latitude])
      .setPopup(popup)
      .addTo(map);
  });
})

</script>

<template>
  <div class="map" ref="mapContainer"></div>
</template>

<style scoped>
.map {
  width: 100%;
  height: 500px;
  color: #000;
}

:deep(.marker) {
  cursor: pointer;
}
</style>
