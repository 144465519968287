<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import PageBody from './PageBody.vue'

const props = defineProps<{
  markdown?: string,
}>();

const renderedBody = ref();
const tableOfContents = ref();

onMounted(async () => {
  const crfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')
  const response = await fetch('/page_preview', {
    method: 'PUT',
    headers: { 'X-CSRF-Token': crfToken!, 'Content-Type': 'application/json' },
    body: JSON.stringify({
      markdown: props.markdown,
    })
  });

  const json = await response.json();
  tableOfContents.value = json.tableOfContents;
  renderedBody.value = json.renderedBody;
})
</script>

<template>
  <PageBody :renderedBody="renderedBody" :tableOfContents="tableOfContents" />
</template>

<style scoped></style>
