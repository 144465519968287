<script lang="ts" setup>
import { Head, Link, router } from '@inertiajs/vue3'
import PageForm from "./components/PageForm.vue"

const props = defineProps<{
  currentUser: { settings: { editSlug: boolean, manualMapMarkerPositioning: boolean } },
  updatePagePath: string,
  showPagePath: string,
  canDestroyPage: boolean,
  destroyPagePath: string,
  title: string,
  slug?: string,
  body?: string,
  images: { id: string, url: string }[],
  enabledImageIds: string[],
  externalLinks: { url: string }[],
  latitude?: number,
  longitude?: number,
  osmType?: string,
  osmId?: string,
  osmName?: string,
  tagsAsStrings?: string[],
}>();

const confirmDelete = () => {
  if (!confirm(`Are you sure you want to delete “${props.title}”?`)) {
    return;
  }
  router.delete(props.destroyPagePath)
};
</script>

<template>

  <Head title="Edit Page"></Head>
  <Link :href="showPagePath">&lt; cancel</Link>
  <div class="title">
    <h1>Edit “{{ title }}”</h1>
    <div>
      <button v-if="canDestroyPage" class="delete-button" type="button" @click="confirmDelete">Delete</button>
    </div>
  </div>

  <PageForm :editing="true" :editSlug="currentUser.settings.editSlug"
    :manualMapMarkerPositioning="currentUser.settings.manualMapMarkerPositioning" :actionUrl="updatePagePath"
    :title="title" :slug="slug" :body="body" :images="images" :enabledImageIds="enabledImageIds"
    :externalLinks="externalLinks" :latitude="latitude" :longitude="longitude" :osmType="osmType" :osmId="osmId"
    :osmName="osmName" :tagsAsStrings="tagsAsStrings" />
</template>

<style scoped>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-button {
  font-size: 0.7em;
  background: #d94b4b;
  border-color: #ffbfbf;
  border-width: 3px;
}
</style>
